import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const UserCheck2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14 19a6 6 0 0 0-12 0' }],
    ['circle', { cx: '8', cy: '9', r: '4' }],
    ['polyline', { points: '16 11 18 13 22 9' }],
  ],
];

export default UserCheck2;
