import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const PenSquare: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7' },
    ],
    ['path', { d: 'M18.5 2.5a2.12 2.12 0 0 1 3 3L12 15l-4 1 1-4Z' }],
  ],
];

export default PenSquare;
