import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const BellMinus: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M18.4 12c.8 3.8 2.6 5 2.6 5H3s3-2 3-9c0-3.3 2.7-6 6-6 1.8 0 3.4.8 4.5 2',
      },
    ],
    ['path', { d: 'M10.3 21a1.94 1.94 0 0 0 3.4 0' }],
    ['path', { d: 'M15 8h6' }],
  ],
];

export default BellMinus;
