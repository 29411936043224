import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Dot: IconNode = [
  'svg',
  defaultAttributes,
  [['circle', { cx: '12.1', cy: '12.1', r: '1' }]],
];

export default Dot;
