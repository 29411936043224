import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronUp: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'm18 15-6-6-6 6' }]],
];

export default ChevronUp;
