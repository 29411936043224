import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Users2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14 19a6 6 0 0 0-12 0' }],
    ['circle', { cx: '8', cy: '9', r: '4' }],
    ['path', { d: 'M22 19a6 6 0 0 0-6-6 4 4 0 1 0 0-8' }],
  ],
];

export default Users2;
