import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const BookX: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20' },
    ],
    ['path', { d: 'm14.5 7-5 5' }],
    ['path', { d: 'm9.5 7 5 5' }],
  ],
];

export default BookX;
