import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronRight: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'm9 18 6-6-6-6' }]],
];

export default ChevronRight;
