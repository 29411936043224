import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const HdmiPort: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M22 9a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1l2 2h12l2-2h1a1 1 0 0 0 1-1Z',
      },
    ],
    ['path', { d: 'M7.5 12h9' }],
  ],
];

export default HdmiPort;
