import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Bot: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '18', height: '10', x: '3', y: '11', rx: '2' }],
    ['circle', { cx: '12', cy: '5', r: '2' }],
    ['path', { d: 'M12 7v4' }],
    ['line', { x1: '8', x2: '8', y1: '16', y2: '16' }],
    ['line', { x1: '16', x2: '16', y1: '16', y2: '16' }],
  ],
];

export default Bot;
