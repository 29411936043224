import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ShoppingBasket: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm5 11 4-7' }],
    ['path', { d: 'm19 11-4-7' }],
    ['path', { d: 'M2 11h20' }],
    [
      'path',
      { d: 'm3.5 11 1.6 7.4a2 2 0 0 0 2 1.6h9.8c.9 0 1.8-.7 2-1.6l1.7-7.4' },
    ],
    ['path', { d: 'm9 11 1 9' }],
    ['path', { d: 'M4.5 15.5h15' }],
    ['path', { d: 'm15 11-1 9' }],
  ],
];

export default ShoppingBasket;
