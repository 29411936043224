import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const UserPlus2: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14 19a6 6 0 0 0-12 0' }],
    ['circle', { cx: '8', cy: '9', r: '4' }],
    ['line', { x1: '19', x2: '19', y1: '8', y2: '14' }],
    ['line', { x1: '22', x2: '16', y1: '11', y2: '11' }],
  ],
];

export default UserPlus2;
