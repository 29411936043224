import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Minus: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M5 12h14' }]],
];

export default Minus;
