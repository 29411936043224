import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronDown: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'm6 9 6 6 6-6' }]],
];

export default ChevronDown;
