import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const SaveAll: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M6 4a2 2 0 0 1 2-2h10l4 4v10.2a2 2 0 0 1-2 1.8H8a2 2 0 0 1-2-2Z' },
    ],
    ['path', { d: 'M10 2v4h6' }],
    ['path', { d: 'M18 18v-7h-8v7' }],
    ['path', { d: 'M18 22H4a2 2 0 0 1-2-2V6' }],
  ],
];

export default SaveAll;
