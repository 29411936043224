import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const ChevronLeft: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'm15 18-6-6 6-6' }]],
];

export default ChevronLeft;
