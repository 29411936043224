import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Tally1: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M4 4v16' }]],
];

export default Tally1;
