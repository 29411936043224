import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Archive: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['rect', { width: '20', height: '5', x: '2', y: '4', rx: '2' }],
    ['path', { d: 'M4 9v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9' }],
    ['path', { d: 'M10 13h4' }],
  ],
];

export default Archive;
