import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const HeartOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '2', y1: '2', x2: '22', y2: '22' }],
    [
      'path',
      {
        d: 'M16.5 16.5 12 21l-7-7c-1.5-1.45-3-3.2-3-5.5a5.5 5.5 0 0 1 2.14-4.35',
      },
    ],
    [
      'path',
      {
        d: 'M8.76 3.1c1.15.22 2.13.78 3.24 1.9 1.5-1.5 2.74-2 4.5-2A5.5 5.5 0 0 1 22 8.5c0 2.12-1.3 3.78-2.67 5.17',
      },
    ],
  ],
];

export default HeartOff;
